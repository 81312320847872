import React, { useState } from "react";

export default function Input({ onSend,isSend  }) {
  const [text, setText] = useState("");
  const handleInputChange = (e) => {
    const inputText = e.target.value;
    // Regex to limit text to MAX_LENGTH characters
    const validText = inputText.match(new RegExp(`^.{0,${process.env.REACT_APP_INPUT_MAX_LENGTH}}`))?.[0] || "";

    setText(validText);
  };

  const handleSend = e => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  return (
    <div className="input px-3">
      <form onSubmit={handleSend}>
        <div className="d-flex align-items-center p-2">
          <div>
            <i className="mdi mdi-account rounded-circle px-2 py-1 lh-sm fs-5 text-white bg-dark-blue"></i>
          </div>
          <input
            className="form-control mx-2 shadow-none"
            type="text"
            placeholder="Ask your question here"
            autoFocus
            onChange={handleInputChange}
            value={text}
            // disabled={isSend}
          />
          <button
            className="btn send-btn btn-primary"
            disabled={ text == ''} // Disable button when input is empty or isSending is true
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3607 0.318869L1.90611 6.7106C1.57878 6.83778 1.29597 7.05822 1.09273 7.3446C0.88949 7.63098 0.774762 7.97071 0.762766 8.32168C0.75077 8.67264 0.842034 9.01941 1.02524 9.319C1.20845 9.61859 1.47556 9.85783 1.79344 10.0071L8.05432 12.9461L10.9946 19.207C11.1438 19.5249 11.3831 19.792 11.6827 19.9752C11.9823 20.1583 12.3291 20.2496 12.68 20.2376C13.031 20.2255 13.3707 20.1108 13.6571 19.9075C13.9435 19.7043 14.1639 19.4214 14.291 19.0941L20.6828 2.64098C20.8087 2.31653 20.8376 1.96244 20.7659 1.62187C20.6941 1.2813 20.5249 0.968935 20.2788 0.722831C20.0327 0.476728 19.7203 0.307507 19.3798 0.235782C19.0392 0.164058 18.6851 0.192926 18.3607 0.318869ZM12.4624 13.8915L11.0269 10.8348C10.849 10.4559 10.5441 10.1511 10.1652 9.97333L7.10964 8.53896L15.8627 5.13892L12.4624 13.8915Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div>
          <p className="disclaimer info-style">
            <b>Info:</b> {process.env.REACT_APP_DISCLAIMER}
          </p>
        </div>
      </form>
    </div>
  );
}
